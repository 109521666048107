import { derived } from "svelte/store";
import { realmUser } from "./auth";
const getDB = (user) => user.mongoClient("mongodb-atlas").db("cba");
const COLLECTION_POINTS = "collection-points";
const AREAS = "areas";
export const areas = derived(realmUser, async ($realmUser) => {
    const areasLs = localStorage.getItem(AREAS);
    if (areasLs) {
        return JSON.parse(areasLs);
    }
    const areasDb = await getDB($realmUser).collection(AREAS).find();
    localStorage.setItem(AREAS, JSON.stringify(areasDb));
    return areasDb;
});
export const collectionPoints = derived(realmUser, async ($realmUser) => {
    const areasLs = localStorage.getItem(COLLECTION_POINTS);
    if (areasLs) {
        return JSON.parse(areasLs);
    }
    const pointsDb = await getDB($realmUser)
        .collection(COLLECTION_POINTS)
        .find();
    localStorage.setItem(COLLECTION_POINTS, JSON.stringify(pointsDb));
    return pointsDb;
});
export const treePiles = derived(realmUser, ($realmUser) => getDB($realmUser).collection("tree-piles").find());
