import { App } from "realm-web";
import { writable, get } from "svelte/store";
function createRealmUser() {
    const app = new App({ id: "cba-collector-fiqgw" });
    const { subscribe, set, update } = writable(app.currentUser);
    return {
        subscribe,
        login: (credentials) => {
            return new Promise((resolve, reject) => {
                app
                    .logIn(credentials)
                    .then((user) => {
                    set(user);
                    console.log(`Successfully loggedin with user ${user.id}`);
                    resolve();
                })
                    .catch(reject);
            });
        },
        logout: () => update((user) => {
            user.logOut().then(() => {
                console.log(`Successfully logged out with user ${user.id}`);
            });
            return null;
        }),
    };
}
export const realmUser = createRealmUser();
export function getRealmUser() {
    return get(realmUser);
}
export function loggedIn() {
    return getRealmUser() !== null;
}
export function loggedOut() {
    return getRealmUser() === null;
}
